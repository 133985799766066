<template>
  <div class="content">
    <OfferNannyInputDetails
      v-model:offer-title="offerNanny.details.title"
      v-model:offer-description="offerNanny.details.description"
      v-model:fee-hour="offerNanny.details.rate.hour"
      v-model:fee-month="offerNanny.details.rate.month"
      v-model:offer-tel="offerNanny.details.phone"
      v-model:offer-tel-proven="offerNanny.details.phoneProven"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext @click="handleCreateOffer()" :isDisabled="!isValid" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useOfferNannyStore } from "@/stores/offerNanny";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";

import { ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME } from "@/router/constants";

import OfferNannyInputDetails from "@/components/Offer/Nanny/OfferNannyInputDetails.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext";

export default {
  components: {
    OfferNannyInputDetails,
    ButtonPrimaryNext,
  },

  setup() {
    const offerNanny = useOfferNannyStore();
    const userStore = useUserStore();
    const isValid = ref(false);

    const router = useRouter();
    const handleCreateOffer = async () => {
      try {
        if (offerNanny.meta.status === "published") {
          await offerNanny.updateOffer();
          router.push({ name: ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME });
          return;
        }

        offerNanny.meta.status = "published";
        await offerNanny.createOffer();
        await userStore.refetchUserStore();
        router.push({ name: ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME });
      } catch (error) {
        offerNanny.meta.status = "draft";
        console.log(error);
        alert(error.message);
      }
    };

    return {
      handleCreateOffer,
      offerNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 120px;
}

@media (max-width: 1200px) {
}
</style>
